import { createLocaleMessages } from '@/utils/i18n';
import { basicColumnTitleEnUS } from './basicColumnTitle/en-US';
import { DefineLocalConfig } from './typings';

export default createLocaleMessages<DefineLocalConfig>({
  scanCode: {
    success: {
      title: 'Scanned successfully',
      des: `Tap "Allow" in WeChat to log in`,
    },
    reject: {
      title: 'Login has been canceled',
      des: 'Scan again to log in, or close the window',
    },
  },
  menuCollapse: {
    expand: 'Collapse Sidebar',
    collapse: 'Expand Sidebar',
  },
  generateSuccess: 'Generation successful! Please download.',
  home: {
    button: 'Start',
    knows: {
      des: 'Ask a question, and with one click, you can search the latest global literature, guidelines, and conferences. It can also help you generate clinical recommendations, research reviews, and conference reports.',
      tooltip: '',
    },
    project: {
      des: 'Batch upload literature and use the AI table tool to efficiently extract literature data and accurately filter documents. You can also specify multiple documents for real-time AI Q&A.',
      tooltip: '',
    },
    read: {
      des: 'Upload a single literature PDF and receive a PPT report in 30 seconds. We provide different interpretation schemes for various literature types, and AI real-time Q&A is \nsupported.',
      tooltip: '',
    },
  },
  homeHUIZHI: {
    button: 'Start',
    knows: {
      des: 'Ask a question, and with one click, you can search the latest global literature, guidelines, and conferences. It can also help you generate clinical recommendations, research reviews, and conference reports.',
      tooltip: '',
    },
    project: {
      des: 'Batch upload literature and use the AI table tool to efficiently extract literature data and accurately filter documents. You can also specify multiple documents for real-time AI Q&A.',
      tooltip: '',
    },
    read: {
      des: 'Upload a single literature PDF and receive a PPT report in 30 seconds. We provide different interpretation schemes for various literature types, and AI real-time Q&A is \nsupported.',
      tooltip: '',
    },
  },
  bindPhone: {
    info: 'Please bind the phone number and use it',
  },
  welcome: {
    enter: "Let's start",
    slogon: 'Medical Searching and Content Creation Platform in AI Era',
    oneStop:
      'Knowledge, Insights, Creating. Revolutionize the healthcare industry with AI.',
    start: "Let's start",
    mediflow: 'What is KnowS?',
    ai: {
      title: 'AI Search',
      des: 'Large model-based question and answer engine, support natural language, keyword search evidence to obtain summary answers',
    },
    paper: {
      title: 'SLR',
      des: 'The online AI literature data management tool supports the tabular batch extraction and generation of article summary, research value and other information',
    },
    read: {
      title: 'Chat with PDF',
      des: 'Chat with PDF\nGenerate PPT in 10 seconds',
    },
    policy: 'User Agreement',
    privacy: 'Privacy Policy',
    contact: 'Contact',
  },
  basicColumnTitle: basicColumnTitleEnUS,
  menu: {
    home: 'Home',
    knows: 'AI Search',
    manage: 'SLR',
    read: 'Chat with Paper',
    open: 'Open',
    create: 'Unnamed',
  },
  menuHUIZHI: {
    home: 'Home',
    knows: 'AI Search',
    manage: 'SLR',
    read: 'Chat with Paper',
    open: 'Open',
    create: 'Unnamed',
  },
  chatPdfManage: {
    upload: {
      single: {
        title: 'Chat with PDF',
        desc: 'Chat with PDF\nGenerate PPT in 10 seconds',
        buttonText: 'Upload your PDF',
      },
      multiple: {
        title: 'Analyze with Multiple PDFs',
        desc: 'Comprehensive analysis of multiple documents.',
        buttonText: 'Coming soon',
      },
      loading: 'Uploading...',
      parsing: 'Parsing...',
      limit: 'The file size cannot exceed 50MB',
    },
    list: {
      title: 'My Files',
    },
    detail: {
      file_title: {
        abstract: 'Abstract',
        origin: 'Original document',
      },
      gen_ppt: {
        download: 'Download PPT',
        generating: 'PPT Generating',
        generate: 'Generate PPT',
      },
    },
  },
  manage: {
    unnamed: 'Unnamed',
    title: 'My Collections',
    newProjectImport: 'New Collection',
    updateSortLabel: 'By update time, from newest to oldest',
    createSortLabel: 'By create time, from newest to oldest',
    total: '{total} in total',
    des: 'Support questions, DOI, PMID, search query in Pubmed, upload PDF & Excel files and import documents',
  },
  created: 'Created',
  updated: 'Updated',
  ago: 'ago',
  phone: 'Phone',
  bind: 'Bind',
  weChat: 'WeChat',
  change: 'Change',
  logout: 'Log Out',
  confirm: 'Confirm',
  cancel: 'Cancel',
  delete: 'Delete',
  rename: 'Rename',
  recover: 'Recover',
  undo: 'Undo',
  clearFilter: 'Clear filter',
  filter: 'Filter',
  pin: {
    left: 'Pin to Left',
    right: 'Pin to Right',
  },
  hide: 'Hide',
  ascending: 'Ascending',
  descending: 'Descending',
  sortDateAsc: 'Oldest to Newest',
  sortDateDesc: 'Newest to Oldest',
  sortNumberAsc: 'Smallest to Biggest',
  sortNumberDesc: 'Biggest to Smallest',
  selectAll: 'Select All',
  introductionEvidence: {
    title: 'Import Evidence',
    limit: {
      row: 'The max size of one search is 10,000 items',
      info: 'The max size of one import is 10,000 items',
    },
    question: 'question',
    finding: 'searching',
    importAll: 'Import all',
    uploadText: 'Upload PDF file',
    identified: 'Identified evidence',
    ask: {
      title: 'Ask',
      placeholder:
        'Add evidence by asking questions, or import specified evidence...',
      enter: 'Search by AI',
    },
    doi: {
      title: 'DOI',
      placeholder: 'Please enter the document DOI',
      enter: 'Add',
    },
    pmid: {
      title: 'PMID',
      placeholder: 'Please enter the document PMID',
      enter: 'Add',
    },
    pubmed: {
      title: 'Pubmed',
      placeholder: 'Please enter search query in Pubmed',
      enter: 'Add',
    },
    upload: {
      title: 'Upload PDF files',
    },
    excel: {
      title: 'Upload Excel',
      fileRuleTooltip: 'The first row of Excel must be the column names',
    },
  },
  excelUploadModal: {
    title:
      'Please select the "Literature Title Column" and "Literature Abstract Column" in the Excel file',
    description:
      'AI will extract information by default based on these two columns.',
    label: {
      title: 'Literature Title Column',
      abstract: 'Literature Abstract Column',
    },
    selectColumn: 'Select columns',
    warnRequireSelectTitle: 'Select the "Literature Title" column',
    warnSameSelect:
      '"Literature Title" and "Literature Abstract" cannot be the same',
  },
  export: 'Export',
  uploadLocalFiles: 'Upload PDF files',
  dropUpload: 'Drag and drop the file anywhere to upload it',
  projectName: 'Collection name',
  addComment: 'Add Notes',
  changeWeChatTitle: 'Change the binding wechat',
  weChatLoginTitle: 'Wechat scan code login',
  changeVerifyCode: 'Change the SMS authentication login',
  changeWeChatScanCode: 'Change wechat scan code login',
  login: {
    text: 'Log in',
    email: {
      change: 'Log in with email',
    },
  },
  verifyMessageTitle: 'Verify SMS',
  getVerifyCode: 'code',
  sms: {
    login: {
      phone: {
        place: 'enter your phone number',
        error: {
          info: 'Please enter the correct phone number',
        },
      },
    },
  },
  autoRegis: 'New users will be automatically registered',
  seconds: 's',
  verifyCodeError: 'The verification code is incorrect',
  machineVerify: 'Machine verification',
  refresh: 'refresh',
  refresh1: 'Refresh',
  clearConversation: 'Clear conversation',
  pdfParsing: 'Parsing PDF, please hold on a moment...',
  enterGraphicVerify: 'Enter the graphic verification code above',
  email: {
    login: {
      passwordPlaceHolder: 'Please enter password',
      placeholder: 'Please enter your email address',
      tips: 'New user registration',
    },
    register: {
      title: 'Register a new account',
      success: {
        msg: 'Email has been sent, please go to the email link to activate',
      },
      submit: 'Create an account',
      setPassword: 'Password',
      confirmPassword: 'Confirm password',
      tips: 'Already have an account',
      goLogin: 'Log in',
    },
    forget: {
      text: 'Forgot password',
      desc: 'Enter the email address you used to join and we will send you the password you need to reset.',
      success: {
        msg: 'Email has been sent, please go to the email link to reset the password',
      },
      submit: 'Verify and reset',
    },
    reset: {
      text: 'Reset password',
      password: 'New password',
      oldPassword: 'Old password',
      confirmPassword: 'Confirm new password',
    },
    message: {
      validEmail: 'Please enter the correct email address',
      confirmPassword: {
        empty: 'Please confirm the password',
        inconformity: 'Two passwords do not match',
      },
    },
  },
  machine: {
    verify: {
      error: 'The verification code is incorrect',
    },
  },
  question: {
    input: {
      placeholder: "I'd like to know more about the evidence",
    },
  },
  insert: {
    blank: {
      column: 'Insert blank column',
    },
  },
  display: {
    fields: 'Edit Column',
  },
  wantGetInfo: 'You may want to know',
  import: {
    loading: 'Importing',
    text: 'Import',
    record: 'Import History',
    done: 'Done',
    success: {
      info: 'Import successfully',
    },
  },
  blank: 'Blank',
  filters: 'Filters',
  fileUpload: {
    sizeLimit: 'File size must be less than {count}',
  },
  project: {
    process: {
      loadingText: 'Remaining {count} items',
      calculating: 'Calculating..',
      stop: 'Stop',
      halting: 'Stopping',
      delete: {
        desc: 'Do you want to interrupt and cancel all tasks currently running for this collection?',
        ok: 'Confirm Stop',
      },
      failed: '{count} failed',
      retry: 'Retry',
      failedText: 'AI run failed, click to retry',
    },
    popconfirm: {
      delete: {
        title: 'Are you sure to delete',
        desc: 'Are you sure to delete the selected item?',
      },
    },
    prompFields: {
      pdf: 'PDF',
    },
    fields: {
      authors: basicColumnTitleEnUS.authors,
      journal: basicColumnTitleEnUS.journal,
      blankImpactFactorValue: 'Blank',
      impactFactor: 'IF: {value}',
    },
    blankQuestionTitle: 'Blank',
    blankQuestionContent: 'Blank',
    back: 'Back',
    analyzing: 'Analyzing...',
    relatedContent: 'Related Content',
    relatedTrials: 'Related Trials',
    references: 'References',
    citations: 'Citations',
    blankColumn: 'Blank Column',
    newBlankColumn: 'New Blank Column',
    newColumnButton: 'New {name} Column',
    newColumnInputPlaceholder: "I'd like to know more about…",
    batchCellModal: {
      button: 'Batch Edit',
      title: 'Batch Edit',
      placeholder: {
        selectColumn: 'Select column',
        text: 'Enter text',
        number: 'Enter number',
        date: 'Enter date',
      },
      operate: {
        clear: 'Clear',
        replace: 'Clear and replace',
        unshift: 'Add at the beginning of the text',
        push: 'Add at the end of the text',
      },
    },
    knows: {
      actionBar: {
        newButton: 'Dialogue with Selected Evidence',
        newLimitTip:
          'If the number of pieces of evidence exceeds {count}, the system will default to answering based on the first {count}.',
      },
      drawer: {
        title: 'Chat with selected papers',
        close: 'Close',
        retry: 'Retry',
        answer: 'Answer: ',
        retryByUserCancel: 'User has stopped responding',
        stop: 'Stop',
        new: 'New conversation',
        networkError: 'Network error, please run again',
        feedbackTitle: 'Feedback',
        feedbackPlaceHolder: 'Feedback description',
        input: {
          placeholder: 'Enter your question',
          scopeLabel: 'Scope of Q&A: ',
          chooseScopeTitle: 'Select Q&A Scope',
          scopeContentLimitTip:
            'The selected scope is too long. The answer will be based on limited content',
          maxInputLengthLimitTip: 'Please optimize the length of your question',
          chooseAtLeastOneColumn:
            'Please choose at least one column for the conversation.',
        },
        evidences: {
          title: 'Selected Evidence Scope {count}',
          titleTour:
            'The system will answer based on the {count} pieces of evidence you have selected.',
          tourConfirm: 'Got it',
          abstract: 'Abstract',
          deleted: 'The evidence has been deleted. ',
          selectedFirst: 'Please select the evidence first.',
        },
        history: {
          title: 'History of Q&A',
          lengthLabel: 'Scope of Evidence:',
          lengthValue: '{count} pieces',
        },
      },
    },
    table: {
      details: 'Details',
      acquiring: 'Acquiring',
      configHelper: 'Edit',
      configHelperDone: 'Done',
      uploadFile: 'Upload PDF File',
      uploadingFile: 'Uploading',
      sortingInfo: {
        publishDateAsc: 'Oldest to Newest',
        publishDateDesc: 'Newest to Oldest',
      },
      filterInfo: {
        headerButton: '{count} Filter(s): ',
        dropdownTitle: 'Filters',
        itemSelected: '{count} value(s) selected',
      },
      filterMenu: {
        include: 'Include {total}',
        exclude: 'Not included {total}',
        reverse: 'Select Invert',
        all: 'Select All',
      },
      selected: '{count} evidence(s) selected',
      filterInputPlaceholder: 'Example：2020',
      filterUniqueValuesTotal: '{total}',
      deleteConfirm: {
        description: 'Are you sure to delete these {count} evidence(s) ?',
        successMessage: '{count} evidence(s) deleted',
      },
    },
    citationModal: {
      format: 'Format',
      cancel: 'Cancel',
      copy: 'Copy',
    },
  },
  records: {
    header: {
      total: '{total} in total',
    },
  },
  expand: 'Expand',
  fold: 'Fold',
  upload: {
    tooLarge: {
      error: 'The file is too large and fails to be uploaded. Procedure',
      limit: {
        image: 'The maximum image limit is',
      },
    },
  },
  time: {
    unit: {
      now: 'now',
      minutes: 'minutes',
      hours: 'hours',
      days: 'days',
      weeks: 'weeks',
      months: 'months',
      years: 'years',
    },
  },
  ok: 'OK',
  prompt: {
    text: 'Describe your problem',
    example:
      'Example:Extract the words describing characteristics of the patients from the Text.',
    placeholder: 'Enter "@" to fill in column contents',
    clearConfirm: {
      // 确定清空既往值
      title: 'Confirm clearing the values',
      // 由于您的问题描述中对输出格式要求有变化，该列的结构会改变并清空既往值
      description:
        'Due to the change in the output format requirement mentioned in your problem description, the structure of that column will be modified and the previous values will be cleared.',
    },
    tooltip1:
      'When you only provide a question or instruction, we will default to asking about the "title" and "abstract" columns.',
    tooltip2:
      'You can also specify the column name you want to ask about by using "@" symbol.',
    example1: 'Example 1: Age of the patient',
    example2:
      'Example 2: Determine whether the included patients have NSCLC based on the @title@ abstract.',
  },
  notification: {
    copied: 'Copied successfully',
    noRowsSelected: 'Please select rows first',
    summarizeFirst: 'Please summarize first',
    guideDetailsPageIsUnderConstruction:
      'The guide details page is under construction',
    meetingDetailsPageIsUnderConstruction:
      'The meeting details page is under construction',
    isReceivingEvidences: 'Responding',
  },
  tooltip: {
    openInNewWindow: 'Open in new window',
    copyDOI: 'Copy DOI',
    copyPMID: 'Copy PMID',
    copyUrl: 'Copy URL',
    download: 'Download',
    generateCitation: 'Generate Citation',
  },
  copy: 'Copy',
  months: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },
  detail: {
    translation: {
      translate: 'Translation',
      successTip: 'Translation successful, click to download.',
      download: 'Click to Download',
    },
  },
  // knows module
  knows: {
    thematicReport: 'Thematic Report',
    thematicReportDrawer: {
      analyzeQuestion: 'Analyze Question',
      generateOutline: 'Generate Outline',
      generatePPT: 'Generate PPT',
      regeneratePPT: 'Regenerate PPT',
      history: 'History',
    },
    filters: {
      publishedSince: 'Published since',
      studyType: 'Study Type',
      Guidelines: 'Guidelines/Consensus',
      Meta: 'Meta Analysis/Systematic Review',
      RCT: 'RCT',
      ClinicalTrial: 'Clinical Trial (non-RCT)',
      Case: 'Case Series/Case Report',
      Literature: 'Literature Review (non-Systematic Review)',
      OtherTypes: 'Others',
      BasicResearch: 'Basic Research',
      ObservationalStudy: 'Observational Study',
      IF: 'IF',
      Q: 'Q{count}',
      subISTEC: 'ISTEC Sub Disciplines Division ',
      JCR: 'JCR Division',
      FullText: 'Open Access',
    },
    generatePptByIssueButton: 'Generate Academic PPT',
    downloadPptByIssueButton: 'Download Academic PPT',
    searchPlaceholder: 'Ask a Medical Question',
    searchSubmit: 'Search by AI',
    tabHotQuestions: 'Frequently asked questions',
    tabHistory: 'History',
    clearHistory: 'Clear',
    backTop: 'Top',
    summary: 'Summary',
    evidenceSearching: 'Evidence Searching: {count}',
    relevantEvidence: 'Relevant evidence: {count}',
    managePapers: 'Manage',
    summaryMode: {
      clinical: 'Clinical Advice',
      research: 'Academic Review',
      popular_science: 'Popular Science Education',
    },
    dataScope: {
      MEETING: 'Conference',
      PAPER: 'Literature(EN)',
      PAPER_CN: 'Literature(CN)',
      TOP_PAPER: 'High-Quality Literature',
      GUIDE: 'Guidelines',
    },
    evidenceList: {
      summaryError: 'AI Summary Failure',
      summaryProcessing: 'AI Summary In Progress...',
      sort_option: {
        by_time: 'From Recent to Old',
        by_if: 'From High to Low Impact Factor (IF)',
        by_relativity: 'From High to Low Relevance',
      },
    },
  },
  credits: {
    left: '{count} credits left',
    tips: 'One question for one row ≈ 1 credit,\n20~30 credits once per PDF',
    insufficient2: 'insufficient balance',
    insufficient:
      'The estimated consumption for this operation is {count} credits, which exceeds your current balance and is insufficient to run this operation.',
    largeAmountRemind:
      'The estimated consumption of credits for your current operation is {count} credits.',
    largeAmountRemind2:
      'Your operation is about to automatically run over {count} cells, which may incur certain costs. Please confirm again',
    usage: {
      tip: 'View credit usage',
      title: 'Credit usage',
      chat: 'Chat with papers',
      received: 'Received {count} free credits',
      helpColumn: 'Data Extraction',
      noMore: 'No more',
    },
  },
  setting: {
    title: 'Setting',
    language: {
      title: 'Language',
      desc: 'Set your preferred language for interface and AI responses.',
      lang: {
        chinese: ' Chinese',
        english: 'English',
      },
    },
  },
  userInfo: {
    level: {
      label: 'Current Plan',
      upgrade: 'Upgrade',
      renewal: 'Renewal',
      creditPayTip: 'Recharge available for Professional Edition only',

      free: 'Free',
      pro: 'Pro',
      bi_pro: 'Featured',
      personal: 'Personal',
      professional: 'Professional',
      ultimate: 'Ultimate',
    },

    payment: {
      amountPayable: '应付金额',
      productName: '商品名',
      startDate: '生效日期',
      endDate: '时效日期',
      scanToPay: '请扫码完成支付',
      freeGift: '免费赠送',
      payCodeText: 'Please add WeChat customer service to purchase rights.',
    },
    permission: {
      upgrade: 'Unlock Premium Benefits',
      renewal: 'Renew KnowS Pro Edition.',
      baseScope: 'Covers guidelines, Chinese and English literature',
      groupAsk: 'AI Search',
      chat: 'Ask AI search questions',
      scope: 'Covers conferences',
      evidenceList: 'View evidence list',
      evidenceExport: 'Export evidence list',
      evidenceSummary:
        'AI-generated evidence summaries (for clinical, academic, and popular science purposes)',
      groupRead: 'AI Interpretation',
      evidenceDetail: 'Access detailed evidence',
      translate: 'Chinese-English translation',
      aiRead: 'One-click AI interpretation',
      genWord: 'Generate Word reports',
      genPpt: 'Generate PPT presentations',
      fullText: 'Full-text interactive deep reading',
      uploadPdf: 'Support for uploading PDF documents for interpretation',
      morePaperFilter: 'Systematic Literature Review (PC only)',
      multiPaperChat: 'Chat with Multi-evidence (PC only)',
      groupCommon: 'General',
      terminal: 'Sync usage across mobile and PC',
      share: 'Share evidence and summaries',
      response: 'Priority response during peak hours',
      customerService: 'Dedicated one-on-one customer support',
      trial: 'Early access to new AI features',
    },
  },
});
