import { ResponseWrap, unwrapResponse } from '@/utils/request';
import { request } from '@umijs/max';
import qs from 'qs';
import { KnowsResponseWrap } from '../knows';
import {
  BindPhoneParams,
  CaptchaParams,
  CommonLoginParams,
  CreditsHistoryItem,
  FindUserResponse,
  ProtocolItem,
  SMSLoginParams,
  SMSLoginResponse,
  SendEmailParams,
  UpdateUserParams,
  VerifyRuCaptchaParams,
  WeChatLoginParams,
  createEmailAccountResponse,
} from './typings';

/** 获取图形验证码 */
export const getRuCaptcha = async () => {
  try {
    // token & base64
    const res = await request<ResponseWrap<{ graphic: string; token: string }>>(
      '/mediflow/rucaptcha',
    );

    // LOGIN set token
    if (res?.data?.token) {
      localStorage.setItem('sms-token', res.data?.token);
    }

    return res;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const loginServer = async (data: CommonLoginParams) => {
  try {
    const res = await request<
      ResponseWrap<{
        token: string;
      }>
    >('/mediflow/login', {
      data,
      method: 'post',
    });

    if (res.data?.token) {
      localStorage.setItem('token', res.data?.token);
    }

    return res;
  } catch (err) {
    return Promise.reject(err);
  }
};

/** 校验图形验证码 */
export const verifyRuCaptcha = (data: VerifyRuCaptchaParams) => {
  const smsToken = localStorage.getItem('sms-token');
  return request<ResponseWrap<boolean>>('/mediflow/check', {
    method: 'post',
    data: qs.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${smsToken}`, // 重置验证码token
    },
  });
};

/** 获取短信验证码 */
export const getCaptcha = (data: CaptchaParams) => {
  const smsToken = localStorage.getItem('sms-token');
  return request<ResponseWrap<string>>('/mediflow/send_phone_captcha', {
    method: 'post',
    data: qs.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${smsToken}`, // 重置验证码token
    },
  });
};

/** 短信登录 */
export const smsLogin = (data: SMSLoginParams) => {
  return request<ResponseWrap<SMSLoginResponse>>('/mediflow/oauth/sms', {
    method: 'post',
    data: qs.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

/** 获取当前登陆用户 */
export const findUser = () => {
  return request<ResponseWrap<FindUserResponse>>(
    '/mediflow/knows/api/user/info',
    { method: 'GET' },
  );
};

/** 退出登录 */
export const logout = () => {
  return request('/mediflow/logout', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

/** 修改用户信息 */
export const updateUser = (data: UpdateUserParams) => {
  return request('/mediflow/user/update_user_name', {
    method: 'POST',
    data,
  });
};

/** 绑定手机号 */
export const bindPhone = (data: BindPhoneParams) => {
  return request<ResponseWrap<string>>('/mediflow/user/bind_user_phone', {
    method: 'POST',
    data,
  });
};

/** 上传头像 */
export const updateAvatar = (data: { file_key: string }) => {
  return request<ResponseWrap<string>>('/mediflow/user/update_portrait', {
    method: 'POST',
    data,
  });
};

/** 微信登录 */
export const weChatLogin = (data: WeChatLoginParams) => {
  return request<ResponseWrap<any>>('/mediflow/oauth/wechat', {
    method: 'POST',
    data,
  });
};

/** 绑定微信 */
export const bindWeChat = (data: WeChatLoginParams) => {
  return request<ResponseWrap<any>>('/mediflow/user/bind_user_wechat', {
    method: 'POST',
    data,
  });
};

/** 发送邮件 */
export const sendEmail = (data: SendEmailParams) => {
  return request<ResponseWrap<any>>('/mediflow/send_email_captcha', {
    method: 'POST',
    data: qs.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

/** 根据邮箱激活码创建账户 */
export const createEmailAccount = (data: { token: string }) => {
  return request<ResponseWrap<createEmailAccountResponse>>(
    '/mediflow/callback/signin',
    {
      method: 'POST',
      data: qs.stringify(data),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
};

/** 根据邮件激活码更改密码 */
export const changePasswordByEmail = (data: { token: string; pwd: string }) => {
  return request<ResponseWrap<any>>('/mediflow/callback/reset_pwd', {
    method: 'POST',
    data: qs.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

/** 更改密码 */
export const changePassword = (data: {
  old_password: string;
  new_password: string;
}) => {
  return request<ResponseWrap<any>>('/mediflow/user/reset_pwd', {
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/json', //'application/x-www-form-urlencoded',
    },
  });
};

/** 邮箱登录 */
export const loginByEmail = (data: { email: string; password: string }) => {
  return request<ResponseWrap<createEmailAccountResponse>>(
    '/mediflow/oauth/email',
    {
      method: 'POST',
      data: qs.stringify(data),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
};

/** 获取协议列表 */
export const checkProtocolByChannel = async (data: {
  type?: 'LOGIN' | 'VIP';
  channel?: string;
}) => {
  return request<
    ResponseWrap<{
      private_policy: ProtocolItem[];
    }>
  >('/mediflow/knows/api/user/privacy_policy/channel', {
    method: 'get',
    params: data,
  });
};

/** 获取协议列表 */
export const checkProtocolByUser = async (data: { type?: 'LOGIN' | 'VIP' }) => {
  return request<
    ResponseWrap<{
      private_policy: ProtocolItem[];
    }>
  >('/mediflow/knows/api/user/privacy_policy', {
    method: 'get',
    params: data,
  });
};

/** 获取用户可用积分 */
const checkUserCreditsLeft = async () => {
  return request('/mediflow/user/credit', {
    method: 'get',
  });
};

/** 检查可用权限 */
export const checkPermission = async (permissionId: string) => {
  return request(
    `/mediflow/knows/api/permissions/check?permission=${permissionId}`,
    {
      method: 'POST',
    },
  );
};

/** 获取用户积分记录 */
export const fetchUserCreditsList = async (data: { page: number }) => {
  return request<
    ResponseWrap<{
      items: CreditsHistoryItem[];
      total_count: number;
    }>
  >('/mediflow/user/credit_detail', {
    method: 'get',
    params: data,
  });
};

/** 获取用户可用积分 */
export const checkUserCredits = async (): Promise<number> => {
  return unwrapResponse(checkUserCreditsLeft());
};

export function getUserPayCards() {
  return request<
    KnowsResponseWrap<
      {
        name: string;
        type: string;
        label: string;
        config: string;
        cards: {
          product_id: string;
          badge: string;
          button_label: string;
          display_style: 'DEFAULT' | 'RECOMMENDED';
          unit_price: string;
          daily_price: string;
          original_price: string;
          type: 'BASIC' | 'PRO';
          display_name: string;
          open_purchase: boolean;
        }[];
      }[]
    >
  >('/mediflow/knows/api/product/cards', {
    method: 'GET',
  });
}
