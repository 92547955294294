// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/home","parentId":"@@/global-layout","id":"1"},"2":{"path":"/payment","parentId":"@@/global-layout","id":"2"},"3":{"path":"/chatbot","parentId":"@@/global-layout","id":"3"},"4":{"path":"/chatbot/answers","parentId":"@@/global-layout","id":"4"},"5":{"path":"/chatbot/answers/:id/:question","parentId":"@@/global-layout","id":"5"},"6":{"path":"/chatbot/answers/:id/data/:dataId","parentId":"@@/global-layout","id":"6"},"7":{"path":"/welcome/","parentId":"@@/global-layout","id":"7"},"8":{"path":"en","parentId":"7","id":"8"},"9":{"path":"","parentId":"7","id":"9"},"10":{"path":"/project/manage","title":"menu.manage","parentId":"@@/global-layout","id":"10"},"11":{"path":"/interpretation/manage","title":"menu.read","parentId":"@@/global-layout","id":"11"},"12":{"name":"证据集详情","path":"/project/detail/:id","parentId":"@@/global-layout","id":"12"},"13":{"name":"单篇详情","path":"/project/detail/:id/data/:dataId","parentId":"@@/global-layout","id":"13"},"14":{"name":"单篇详情","path":"/interpretation/detail/:id/data/:dataId","parentId":"@@/global-layout","id":"14"},"15":{"name":"AI搜索问答","path":"/knows","parentId":"@@/global-layout","id":"15"},"16":{"name":"AI搜索问答 - AI搜索","path":"/knows/search/:id","parentId":"@@/global-layout","id":"16"},"17":{"name":"AI搜索问答 - 文献详情","path":"/knows/search/:id/data/:dataId","parentId":"@@/global-layout","id":"17"},"18":{"name":"AI搜索问答 - 文献详情","path":"/knows/preview/:id/data/:dataId","parentId":"@@/global-layout","id":"18"},"19":{"name":"授权","path":"/oauth/","parentId":"@@/global-layout","id":"19"},"20":{"path":"wechat","parentId":"19","id":"20"},"21":{"path":"email","parentId":"19","id":"21"},"22":{"path":"*","redirect":"/404","parentId":"@@/global-layout","id":"22"},"23":{"path":"/404","parentId":"@@/global-layout","id":"23"},"@@/global-layout":{"id":"@@/global-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import(/* webpackChunkName: "p__Home__index" */'@/pages/Home/index.tsx')),
'2': React.lazy(() => import(/* webpackChunkName: "p__Payment__index" */'@/pages/Payment/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__ChatBot__index" */'@/pages/ChatBot/index.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "p__ChatBot__Conversation__index" */'@/pages/ChatBot/Conversation/index.tsx')),
'5': React.lazy(() => import(/* webpackChunkName: "p__ChatBot__Conversation__index" */'@/pages/ChatBot/Conversation/index.tsx')),
'6': React.lazy(() => import(/* webpackChunkName: "biz_components__DetailPage__index" */'@/biz_components/DetailPage/index.tsx')),
'7': React.lazy(() => import('./EmptyRoute')),
'8': React.lazy(() => import(/* webpackChunkName: "p__Welcome__EN" */'@/pages/Welcome/EN.tsx')),
'9': React.lazy(() => import(/* webpackChunkName: "p__Welcome__CN" */'@/pages/Welcome/CN.tsx')),
'10': React.lazy(() => import(/* webpackChunkName: "p__Project__Manage__index" */'@/pages/Project/Manage/index.tsx')),
'11': React.lazy(() => import(/* webpackChunkName: "p__Interpretation__Manage__index" */'@/pages/Interpretation/Manage/index.tsx')),
'12': React.lazy(() => import(/* webpackChunkName: "p__Project__Detail__index" */'@/pages/Project/Detail/index.tsx')),
'13': React.lazy(() => import(/* webpackChunkName: "biz_components__DetailPage__index" */'@/biz_components/DetailPage/index.tsx')),
'14': React.lazy(() => import(/* webpackChunkName: "biz_components__DetailPage__index" */'@/biz_components/DetailPage/index.tsx')),
'15': React.lazy(() => import(/* webpackChunkName: "p__knows__index" */'@/pages/knows/index.tsx')),
'16': React.lazy(() => import(/* webpackChunkName: "p__knows__search__index" */'@/pages/knows/search/index.tsx')),
'17': React.lazy(() => import(/* webpackChunkName: "biz_components__DetailPage__index" */'@/biz_components/DetailPage/index.tsx')),
'18': React.lazy(() => import(/* webpackChunkName: "biz_components__DetailPage__index" */'@/biz_components/DetailPage/index.tsx')),
'19': React.lazy(() => import('./EmptyRoute')),
'20': React.lazy(() => import(/* webpackChunkName: "p__Oauth__WeChat__index" */'@/pages/Oauth/WeChat/index.tsx')),
'21': React.lazy(() => import(/* webpackChunkName: "p__Oauth__Email__index" */'@/pages/Oauth/Email/index.tsx')),
'22': React.lazy(() => import('./EmptyRoute')),
'23': React.lazy(() => import(/* webpackChunkName: "p__404" */'@/pages/404.tsx')),
'@@/global-layout': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'/root/workspace/mediflow-web_dev/src/layouts/index.tsx')),
},
  };
}
